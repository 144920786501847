import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import dayjs from 'dayjs'
import { FlespiService } from '@/App/Services/Flespi.service'
import { ReportService } from '@/App/Services/Report.service'
import { DateTimePicker } from '@/App/Components/Pickers'
import { TIME_ITEMS } from '@/App/Pages/Widgets/ChartDialog/SettingsForm/constants'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { Widget } from '@/store/interfaces/widget.interface'
import { ChartLastTime } from '@/types'

const sharedStore = namespace('Shared')
const widgetStore = namespace('Widget')

@Component({
  components: { DateTimePicker }
})
export default class SettingsForm extends Vue {
  @Prop({ required: true })
  private readonly widgetId: string

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @widgetStore.Getter
  private readonly widgetByDashboardId: (dashboardId: string, widgetId: string) => Widget

  public isRealtimeUpdate = false
  public timeItems = TIME_ITEMS
  public dateTimeFrom = Date.now()
  public dateTimeTo = Date.now()
  public lastTime: ChartLastTime = null
  private lastDataSet: ChartDataSet
  private pendingTimeout: number

  private async fetchData(from: number, to: number): Promise<ChartDataSet> {
    try {
      this.$emit('loading')

      const widget = this.widgetByDashboardId(this.$route.params.id, this.widgetId)

      if (widget && widget.deviceId && widget.sensorName) {
        const data = await FlespiService.fetchDeviceMessagesHistory({
          deviceId: +widget.deviceId,
          fields: [widget.sensorName, 'timestamp'],
          from: +from,
          to: +to,
          mathExpression: widget.sensorName,
          mathExpressionField: widget.sensorName,
          widgetId: widget._id,
          dashboardId: this.$route.params.id
        })

        const values: number[] = []
        const labels: string[] = []
        for (const d of data) {
          //@ts-ignore
          labels.push(dayjs.unix(d['timestamp']).format('HH:mm:ss'))
          //@ts-ignore
          values.push(d[widget.sensorName])
        }

        return { labels, values }
      } else {
        throw Error(this.$t('widgetsPage.chartDialog.settingsForm.errorText').toString())
      }
    } catch (e) {
      throw e
    } finally {
      this.$emit('loading')
    }
  }

  private async onFetchDataForCustomTime(): Promise<void> {
    try {
      this.$emit('chartDataChanged', await this.fetchData(this.dateTimeFrom, this.dateTimeTo))
    } catch {}
  }

  public async generateSensorTracingReport(exportType: 'excel' | 'pdf'): Promise<void> {
    try {
      let from: number
      let to: number
      if (this.lastTime === 'custom') {
        from = this.dateTimeFrom
        to = this.dateTimeTo
      } else {
        const dtNow = +(Date.now() / 1000).toFixed(0)
        from = dtNow - this.lastTime!
        to = dtNow
      }

      this.setNotification({ color: 'success', text: this.$t('widgetsPage.chartDialog.settingsForm.reportGeneratingNotificationText').toString() })

      await ReportService.fetch({
        type: 'sensorTracing',
        dashboardId: this.$route.params.id,
        widgetsIds: this.widgetId,
        from,
        to
      })

      await ReportService.download(exportType, 'sensor-tracing')
    } catch {}
  }

  @Watch('lastTime')
  private async lastTimeChanged(val: ChartLastTime): Promise<void> {
    if (!val || val === 'custom') {
      return
    }

    const dtNow = +(Date.now() / 1000).toFixed(0)
    const dateTo = dtNow
    const dateFrom = dtNow - val

    this.lastDataSet = await this.fetchData(dateFrom, dateTo)

    this.$emit('chartDataChanged', this.lastDataSet)
  }

  @Watch('isRealtimeUpdate')
	private async isRealtimeUpdateChanged(val: boolean): Promise<void> {
		if (val) {
			this.pendingTimeout = setInterval(async () => {
				const widget = this.widgetByDashboardId(this.$route.params.id, this.widgetId)

				if (widget) {
					const { lastUpdateTime, lastValue } = widget
					if (this.lastDataSet.values[this.lastDataSet.values.length -1] !== +lastValue) {
            this.lastDataSet.values.push(+lastValue)
            this.lastDataSet.labels.push(dayjs.unix(lastUpdateTime).format('HH:mm:ss'))

            this.$emit('chartDataChanged', this.lastDataSet)
					}
				}
			}, 1000)
		} else {
			clearInterval(this.pendingTimeout)
		}
	}

	private destroyed(): void {
    clearInterval(this.pendingTimeout)
  }
}

export interface ChartDataSet {
  labels: string[]
  values: number[]
}
