import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Menu extends Vue {
  @Prop({ default: false })
  public readonly isShortcutActive!: boolean

  @Prop({ default: false })
  public readonly showMenuShortcut!: boolean

  @Prop({ default: false })
  public readonly isWidgetShared!: boolean

  @Prop({ default: true })
  public readonly showMenuChart: boolean

  public isSettingsIconHovered = false
  public isMenuOpen = false

  public toggleSettingsIconHovered(): void {
    this.isSettingsIconHovered = !this.isSettingsIconHovered
  }

  get isShowShortcut(): boolean {
    return this.showMenuShortcut && !this.isWidgetShared
  }

  get shortcutClass(): 'yellow--text' | 'black--text' {
    return this.isShortcutActive ? 'yellow--text' : 'black--text'
  }

  get shortcutIcon(): string {
    return this.isShortcutActive ? 'fa fa-star' : 'far fa-star'
  }

  get settingsIcon(): string {
    return `fa fa-cog ${this.isSettingsIconHovered ? 'fa fa-spin' : null}`
  }
}