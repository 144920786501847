import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ChartData } from 'chart.js'
import { LineChart } from '@/App/Components/Charts'
import SettingsForm from './SettingsForm/Settings-Form.vue'
import { ChartDataSet } from '@/App/Pages/Widgets/ChartDialog/SettingsForm/SettingsForm'
import { Notification } from '@/store/interfaces/shared.interfaces'

const sharedStore = namespace('Shared')

@Component({
  components: { LineChart, SettingsForm }
})
export default class ChartDialog extends Vue {
  @sharedStore.Mutation
  private setNotification: (notification: Notification) => void

  public isLoading = false
  public isOpen = false
  public widgetId: number | null = null
  public widgetName = ''
  public chartData: ChartData | null = null

  public onOpen(widgetId: number, widgetName: string) {
    this.isOpen = true

    this.widgetId = widgetId
    this.widgetName = widgetName
  }

  public onClose() {
    this.widgetId = null
    this.chartData = null
  }

  public onChartDataChanged(chartDataSet: ChartDataSet) {
    this.chartData = {
      labels: chartDataSet.labels,
      datasets: [
        {
          backgroundColor: '#2296F3',
          fill: false,
          borderColor: '#2296F3',
          data: chartDataSet.values
        }
      ]
    }
  }

  public toggleLoading() {
    this.isLoading = !this.isLoading
  }
}
