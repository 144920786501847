import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Validator } from '@/App/Mixins'

@Component
export default class IFrameForm extends Mixins(Validator) {
  public iFrameLink = ''

  public fillForm(iFrameLink: string): void {
    this.iFrameLink = iFrameLink
  }

  @Watch('iFrameLink', { deep: true })
  private widgetFormChanged(): void {
    this.$emit('formChanged', { iFrameLink: this.iFrameLink })
  }
}
