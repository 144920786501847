import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { GridLayout, GridItem, GridItemData } from 'vue-grid-layout'
import WidgetDialog from './WidgetDialog/Widget-Dialog.vue'
import WidgetCard from './WidgetCard/Widget-Card.vue'
import ChartDialog from '@/App/Pages/Widgets/ChartDialog/Chart-Dialog.vue'
import WidgetsActions from './WidgetsActions/Widgets-Actions.vue'
import CircularLoader from '@/App/Components/CircularLoader/Circular-Loader.vue'
import { WidgetService } from '@/App/Services/Widget.service'
import { UpdateWidget, Widget } from '@/store/interfaces/widget.interface'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { Dashboard } from '@/App/Services/interfaces/dashboard.interface'
import { DashboardService } from '@/App/Services/Dashboard.service'
import { UserInfo } from '@/App/Services/interfaces/user.interface'
import WidgetTemplatesDialog from '@/App/Pages/Widgets/WidgetTemplatesDialog/Widget-Templates-Dialog.vue'
import VisualizationView from '@/App/Pages/Widgets/VisualizationView/Visualization-View.vue'
import TableView from '@/App/Pages/Widgets/TableView/Table-View.vue'


const sharedStore = namespace('Shared')
const dashboardStore = namespace('Dashboard')
const widgetStore = namespace('Widget')
// TODO need clear widgets in store on back to dashboard btn clicked for avoid problem with showing all widgets like a shortcuts
@Component({
  components: { WidgetDialog, WidgetTemplatesDialog, GridLayout, GridItem, WidgetCard, ChartDialog, CircularLoader, VisualizationView, TableView, WidgetsActions }
})
export default class WidgetsPage extends Vue {
  $refs: {
    dialog: HTMLFormElement,
    widgetTemplatesDialog: HTMLFormElement,
    chartDialog: HTMLFormElement,
    tableView: HTMLFormElement,
    visualizationView: HTMLFormElement,
  }

  @sharedStore.State
  private readonly userInfo: UserInfo

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  @dashboardStore.Getter findDashboardById: (id: string) => Dashboard
  @widgetStore.Mutation setWidgets: (widgets: Widget[]) => void
  @widgetStore.Mutation addWidget: (widget: Widget) => void
  @widgetStore.Mutation updateWidget: (widget: UpdateWidget) => void
  @widgetStore.Mutation deleteWidget: (id: string) => void
  @widgetStore.State public widgets: Widget[]
  private isLoading = false
  public isTableView = true
  public layout: GridItemData[] = []
  public dashboard: Dashboard = {
    _id: '',
    name: ''
  }


  public onSave(widget: Widget): void {
    this.addWidget({ ...widget, dashboardId: this.$route.params.id })
    this.layout.push({
      x: widget.layout.x,
      y: widget.layout.y,
      w: widget.layout.w,
      h: widget.layout.h,
      i: widget._id
    })
    this.$refs.visualizationView.addWidget(widget)
  }


  public onUpdate(widget: Widget): void {
    this.updateWidget(widget)
  }


  public async onDelete(id: string, layoutIndex: number): Promise<void> {
    try {
      const title = this.$t('widgetsPage.deleteWidgetConfirmTitle').toString()
      const text = `
        ${this.$t('widgetsPage.deleteWidgetConformText1')}<br/>
        ${this.$t('widgetsPage.deleteWidgetConfirmText2')}<br/>
        ${this.$t('widgetsPage.deleteWidgetConfirmText3')}
    `

      if (await this.confirm({ title, text })) {
        await WidgetService.destroy(this.$route.params.id, id)

        this.deleteWidget(id)
        this.layout.splice(layoutIndex, 1)

        this.setNotification({ text: this.$t('widgetsPage.widgetDeletedNotificationText').toString() })
      }
    } catch {}
  }

  public async onCopy(id: string): Promise<void> {
    try {
      const title = this.$t('widgetsPage.copyWidgetConfirmTitle').toString()
      const text = `
        ${this.$t('widgetsPage.copyWidgetConfirmText1')}<br/>
        ${this.$t('widgetsPage.copyWidgetConfirmText2')}
    `

      if (await this.confirm({ title, text, agreeBtnColor: 'success', disagreeBtnColor: 'error' })) {
        const widget = await WidgetService.copy(this.$route.params.id, id)

        this.onSave(widget)

        this.setNotification({ text: this.$t('widgetsPage.widgetCopedNotificationText').toString() })
        // Open widget edit window after widget was created, if user want to edit new coped widget
        // @ts-ignore
        this.$refs.dialog.open(this.widgets[this.widgets.length - 1])
      }
    } catch {}
  }

  public async onToggleShortcut(id: string, isShortcut: boolean) {
    try {
      await WidgetService.update(this.$route.params.id, id, { isShortcut })
      this.updateWidget({ _id: id, isShortcut })

      const text = isShortcut
        ? this.$t('widgetsPage.widgetAddedShortcutText') .toString()
        : this.$t('widgetsPage.widgetRemovedShortcutText').toString()
      this.setNotification({ text })
    } catch {}
  }


  public viewChanged(): void {
    this.isTableView = !this.isTableView
    const rawView = localStorage.getItem('view')
    const view = rawView ? JSON.parse(rawView) : null
    if (view) {
     const change = view.find((view: any) => view.id === this.dashboard._id)
      if (change) {
        change.isTableView = this.isTableView
      } else {
        view.push({ id: this.dashboard._id, isTableView: this.isTableView })
      }
      localStorage.setItem('view', JSON.stringify(view))
    } else {
      localStorage.setItem('view', JSON.stringify([{ id: this.dashboard._id, isTableView: this.isTableView }]))
    }
  }

  private async mounted() {
    try {
      this.isLoading = true

      const dashboard = this.findDashboardById(this.$route.params.id)
      if (dashboard) {
        this.dashboard = dashboard
      } else {
        this.dashboard = await DashboardService.getById(this.$route.params.id)
      }

      let widgets = await WidgetService.fetch(this.$route.params.id)
      widgets = widgets.map(w => ({ ...w, dashboardId: this.$route.params.id }))
      this.setWidgets(widgets)

      // @ts-ignore layout must be as not required filed in future maybe split this into separate interfaces
      this.widgets.forEach(({ _id, layout: { x, y, w, h } }) =>
        this.layout.push({ x, y, w, h, i: _id }))

      const rawView = localStorage.getItem('view')
      const view = rawView ? JSON.parse(rawView) : null
      const storedView = view ? view.find((v: any) => v.id === this.dashboard._id) : null
      if (storedView) {
        this.isTableView = storedView.isTableView
      }

    } catch {} finally {
      this.isLoading = false
    }
  }

  get isWidgetDialogRender(): boolean {
    return this.userInfo.role !== 'operator'
  }

  public openWidgetDialog(): void {
    this.$refs.dialog.open()
  }

  public openWidgetTemplatesDialog(): void {
    this.$refs.widgetTemplatesDialog.open()
  }

  public openChartDialog(): void {
    this.$refs.chartDialog.open()
  }


}
