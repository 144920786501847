import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const sharedStore = namespace('Shared')

@Component
export default class DashboardActions extends Vue {
    @sharedStore.State
    private readonly userInfo: UserInfo

    public actionItems: ActionItem[] = []



    private mounted(): void {
        if (this.userInfo.role !== 'operator') {
            this.actionItems = [
                { icon: 'fa-plus', tooltip: this.$t('widgetsPage.widgetsActions.addNewWidgetTooltipText').toString(), action: () => this.onOpenWidgetDialog() },
                { icon: 'fa-clone', tooltip: this.$t('widgetsPage.widgetsActions.generateNewWidgetTemplates').toString(), action: () => this.onOpenWidgetTemplatesDialog() },
                { icon: 'fa-code', tooltip: this.$t('widgetsPage.widgetsActions.changeWidgetView').toString(), action: () => this.onOpenTableView() }
            ]
        } else {
            this.actionItems = [
                { icon: 'fa-code', tooltip: this.$t('widgetsPage.widgetsActions.changeWidgetView').toString(), action: () => this.onOpenTableView() }
            ]
        }
    }


    public onOpenWidgetDialog(): void {
        this.$emit('onOpenWidgetDialog')
    }

    public onOpenWidgetTemplatesDialog(): void {
        this.$emit('onOpenWidgetTemplatesDialog')
    }

    public onOpenTableView(): void {
        this.$emit('onOpenTableView')
    }

    get isUserOperator(): boolean {
        return this.userInfo.role !== 'operator'
    }
}

interface ActionItem {
    icon: string
    tooltip: string
    action: () => void
}
