import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { icon, Map } from 'leaflet'

@Component({
  components: { LMap, LTileLayer, LMarker }
})
export default class MapForm extends Vue {
  @Prop({ required: true })
  public readonly options: Position

  public position: [number, number] = [0, 0]
  private map: Map | null = null
  public defaultIcon= icon({
    iconUrl: require('../../../../../assets/mapIcons/mark-icon.png'),
    iconSize: [50, 50]
  })

  private mounted() {
    if (this.options) {
      this.position = [this.options.latitude, this.options.longitude]
    }
  }

  @Watch('options', { deep: true })
  private valueChanged() {
    this.position = [this.options.latitude, this.options.longitude]
  }

  public mapReady(event: Map) {
    this.map = event
    setTimeout(() => event.invalidateSize(), 1000)
  }

  public invalidateSize(): void {
    this.map?.invalidateSize()
    this.position = [this.options.latitude, this.options.longitude]
  }
}

export interface Position {
  latitude: number
  longitude: number
}
