import { Validator } from '@/App/Mixins'
import { Component, Mixins } from 'vue-property-decorator'
import DeviceSelect from '@/App/Components/DeviceSelect/Device-Select.vue'
import DashboardTemplateSelect from '@/App/Components/DashboardTemplateSelect/Dashboard-Template-Select.vue'
import { DashboardService } from '@/App/Services/Dashboard.service'


const FORM_DATA: WidgetFormData = {
    dashboardTemplateId: '',
    deviceId: null
}

@Component({
    components: { DeviceSelect, DashboardTemplateSelect }
})

export default class WidgetTemplatesDialog extends Mixins(Validator) {
    $refs!: {
        form: HTMLFormElement,
        widgetTemplateDialog: HTMLFormElement
}

    public isOpen = false
    public isLoading = false
    public form = false
    public formData: WidgetFormData = { ...FORM_DATA }

    public async open(): Promise<void> {
        this.isOpen = true
    }

    public close(): void {

        this.isOpen = false

        this.$refs.form.resetValidation()
    }

    public async onGenerate(): Promise<void> {
        try {
           this.isLoading = true

         const widgets = await DashboardService.generate(this.$route.params.id, this.formData.deviceId!, this.formData.dashboardTemplateId)
            widgets.forEach(widget => this.$emit('onWidgetStored', widget) )

            this.close()

      } catch (e) {} finally {
         this.isLoading = false
        }
    }

}
interface WidgetFormData {
    deviceId: number | null
    dashboardTemplateId: string
}