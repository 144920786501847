import { Component, Vue } from 'vue-property-decorator'
import MapForm from '@/App/Pages/Widgets/Forms/Map/Map-Form.vue'
import { Widget } from '@/store/interfaces/widget.interface'
import { Position } from '@/App/Pages/Widgets/Forms/Map/MapForm'

@Component({
    components: { MapForm }
})
export default class MapDialog extends Vue {
    public isOpen = false
    public position: [number, number] = [0, 0]
    public widget: Widget

    public async open(position: [number, number] ): Promise<void> {
      this.position = position
      this.isOpen = true
      // @ts-ignore
        setTimeout(() => this.$refs.mapForm.invalidateSize(), 1000)
    }

    get location(): Position {
        return { latitude: this.position[0], longitude: this.position[1] }
    }
}
