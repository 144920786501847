import { Component, Watch } from 'vue-property-decorator'
import { RadialGauge, RadialGaugeOptions, RenderTarget } from 'canvas-gauges'
import { VisualizationCore } from '@/App/Pages/Widgets/Utils'
import { ResizeEvent } from '@/plugins/vue-resize-observer'

@Component
export default class RadialGaugeForm extends VisualizationCore {
  private chart: RadialGauge
  private gaugeOptions: RadialGaugeOptions = {
    ...this.commonOptions,
    valueBox: false
  }

  public mounted(): void {
    this.gaugeOptions.renderTo = this.$refs.el as RenderTarget
    setTimeout(() => {
      this.gaugeOptions = this.fillOptions(this.gaugeOptions, this.options)

      this.chart = new RadialGauge({
        ...this.gaugeOptions,
        height: this.$el.clientHeight - 50,
        width: this.$el.clientWidth
      }).draw()

      this.chart.value = this.value
    }, 50)
  }

  public cardResized({ height, width }: ResizeEvent): void {
    this.gaugeOptions = {
      ...this.gaugeOptions,
      height: height - 50,
      width
    }

   if (this.chart) {
     this.chart.update(this.gaugeOptions)
   }
  }

  @Watch('value')
  private valueChanged(value: number): void {
    this.chart.value = value
  }

  @Watch('options')
  private widgetOptionsChanged(): void {
    this.gaugeOptions = this.fillOptions(this.gaugeOptions, this.options)

    this.chart.update(this.gaugeOptions)
  }

  public beforeDestroy(): void {
    this.chart.destroy()
  }
}
