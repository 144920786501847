import { Component, Prop, Vue } from 'vue-property-decorator'
import { TABLE_HEADERS } from '@/App/Pages/Widgets/TableView/constants'
import TableHeader from '@/App/Components/TableHeader/Table-Header.vue'
import { VuetifyTableHeader } from '@/interfaces/vuetify'
import { UpdateWidget, Widget } from '@/store/interfaces/widget.interface'
import { namespace } from 'vuex-class'
import Menu from './Menu/Menu.vue'
import { WidgetSensorType } from '@/types'
import { WidgetService } from '@/App/Services/Widget.service'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { GridItemData } from 'vue-grid-layout'
import { UserInfo } from '@/App/Services/interfaces/user.interface'
import ChartDialog from '@/App/Pages/Widgets/ChartDialog/Chart-Dialog.vue'
import WidgetDialog from '@/App/Pages/Widgets/WidgetDialog/Widget-Dialog.vue'
import { Dashboard } from '@/App/Services/interfaces/dashboard.interface'
import TilesForm from '../Forms/Tiles/Tiles-Form.vue'
import { getWidgetColor } from '@/App/Pages/Widgets/Utils'
import MapDialog from '@/App/Pages/Widgets/TableView/MapDialog/Map-Dialog.vue'
import { WidgetPositionValue } from '@/App/Services/interfaces/widget.interface'
import { TableHeaderBtn } from '@/App/Components/TableHeader/TableHeader'
import { TABLE_HEADER_BTN } from '@/App/Pages/Widgets/TableView/constants'


const sharedStore = namespace('Shared')
const widgetStore = namespace('Widget')
const dashboardStore = namespace('Dashboard')

@Component({
    components: { TableHeader, Menu, ChartDialog, WidgetDialog, TilesForm, MapDialog }
})
export default class TableView extends Vue {
    $refs: {
        dialog: HTMLFormElement
        mapDialog: HTMLFormElement
    }

    @Prop({ default: true })
    public readonly isMenuShow: boolean

    @Prop({ default: true })
    public readonly isTilesFormShow: boolean


    @Prop({ required: true })
    public readonly isDashboardShared: boolean


    @widgetStore.State public widgets: Widget[]
    @widgetStore.Mutation updateWidget: (widget: UpdateWidget) => void
    @widgetStore.Mutation setWidgets: (widgets: Widget[]) => void
    @sharedStore.Mutation
    private readonly setNotification: (notification: Notification) => void
    @widgetStore.Mutation deleteWidget: (id: string) => void
    @widgetStore.Mutation addWidget: (widget: Widget) => void
    @sharedStore.Action
    private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>
    @sharedStore.State
    private readonly userInfo: UserInfo
    @dashboardStore.Getter findDashboardById: (id: string) => Dashboard



    public isLoading = false
    public isOpen = false
    public headers: VuetifyTableHeader[] = []
    public headerActionsBtn: TableHeaderBtn[] = []
    public isTopMenuHovered = false
    public isMapFormDataInvalid = false
    public search = ''
    private commonFormNames = ['Custom', 'Temperature', 'Voltage', 'Humidity', 'Light (lux)']
    public layout: GridItemData[] = []
    public dashboard: Dashboard = {
        _id: '',
        name: ''
    }


    public onUpdate(widget: Widget): void {
        this.updateWidget(widget)
    }

    public onSearch(text: string): void {
        this.search = text
    }

    public onSave(widget: Widget): void {
        this.addWidget({ ...widget, dashboardId: this.$route.params.id })
            this.layout.push({
                x: widget.layout.x,
                y: widget.layout.y,
                w: widget.layout.w,
                h: widget.layout.h,
                i: widget._id
            })
    }

    public toggleMenuVisibility() {
        this.isTopMenuHovered = !this.isTopMenuHovered
    }

    private async mounted(): Promise<void> {
        this.headers = TABLE_HEADERS
        this.headerActionsBtn = TABLE_HEADER_BTN
        try {
            this.isLoading = true

            let widgets = await WidgetService.fetch(this.$route.params.id)

            widgets = widgets.map(w => ({ ...w, dashboardId: this.$route.params.id }))
            this.setWidgets(widgets)

            // @ts-ignore layout must be as not required filed in future maybe split this into separate interfaces
            this.widgets.forEach(({ _id, layout: { x, y, w, h } }) =>
                this.layout.push({ x, y, w, h, i: _id }))

        } catch {} finally {
            this.isLoading = false
        }
    }

    public async onCopy(id: string): Promise<void> {
        try {
            const title = this.$t('widgetsPage.copyWidgetConfirmTitle').toString()
            const text = `
        ${this.$t('widgetsPage.copyWidgetConfirmText1')}<br/>
        ${this.$t('widgetsPage.copyWidgetConfirmText2')}
    `

            if (await this.confirm({ title, text, agreeBtnColor: 'success', disagreeBtnColor: 'error' })) {
                const widget = await WidgetService.copy(this.$route.params.id, id)

                this.onSave(widget)

                this.setNotification({ text: this.$t('widgetsPage.widgetCopedNotificationText').toString() })
                // Open widget edit window after widget was created, if user want to edit new coped widget
                // @ts-ignore
                this.$refs.dialog.open(this.widgets[this.widgets.length - 1])
            }
        } catch (e) {
        }
    }

    public async onDelete(id: string): Promise<void> {
        try {
            const title = this.$t('widgetsPage.deleteWidgetConfirmTitle').toString()
            const text = `
        ${this.$t('widgetsPage.deleteWidgetConformText1')}<br/>
        ${this.$t('widgetsPage.deleteWidgetConfirmText2')}<br/>
        ${this.$t('widgetsPage.deleteWidgetConfirmText3')}
    `

            if (await this.confirm({ title, text })) {

                await WidgetService.destroy(this.$route.params.id, id)

                this.deleteWidget(id)
                this.widgets = this.widgets.filter(w => w._id !== id)
                this.layout = this.layout.filter(l => l.i !== id)

                this.setNotification({ text: this.$t('widgetsPage.widgetDeletedNotificationText').toString() })
            }
        } catch {}
    }


    public async onToggleShortcut(id: string, isShortcut: boolean) {
        try {
            await WidgetService.update(this.$route.params.id, id, { isShortcut })
            this.updateWidget({ _id: id, isShortcut })

            const text = isShortcut
                ? this.$t('widgetsPage.widgetAddedShortcutText') .toString()
                : this.$t('widgetsPage.widgetRemovedShortcutText').toString()
            this.setNotification({ text })
        } catch {}
    }


    public isMenuShortcutShow(id: string): boolean {
        let widget
        this.widgets.forEach(w => {
            if (w._id === id) {
                widget = w
            }
        })
        if (widget) {
            // @ts-ignore
            return widget.sensorType !== 'iFrame' && widget.sensorType !== 'Map'
        } else {
            return false
        }

    }


    public isMapFormShow(id: string): boolean {
        let widget
        this.widgets.forEach(w => {
            if (w._id === id) {
                widget = w
            }
        })
        if (widget) {
            // @ts-ignore
            return this.widget.sensorType === 'Map' && !this.isMapFormDataInvalid
        } else {
            return false
        }

    }

    public isMenuChartShow(id: string): boolean {
        let widget
        this.widgets.forEach(w => {
            if (w._id === id) {
                widget = w
            }
        })
        if (widget) {
            const executedTypes: WidgetSensorType[] = ['iFrame', 'Map', 'Custom digital', 'Toggle']
            // @ts-ignore
            return !executedTypes.includes(widget.sensorType)
        } else {
            return false
        }

    }

    public getValueColor(id: string): string {
        const widget = this.widgets.find(w => w._id === id)
        if (widget) {
            return getWidgetColor(widget)
        }
        return ''
    }

    get isWidgetDialogRender(): boolean {
        return this.userInfo.role !== 'operator'
    }


    public mapFormData(id: string): [number, number] | undefined {
        const widget = this.widgets.find(w => w._id === id)
        if (widget) {
            const { latitude, longitude } = JSON.parse(widget.lastValue) as WidgetPositionValue
            return [ latitude, longitude ]
        }
        return undefined
    }

}

