import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import ColorPicker from '@/App/Components/ColorPicker/Color-Picker.vue'
import DeviceSelect from '@/App/Components/DeviceSelect/Device-Select.vue'
import SettingsSelect from '@/App/Components/SettingsSelect/Settings-Select.vue'
import { WidgetSensorType } from '@/types'
import { Validator } from '@/App/Mixins'

@Component({
  components: { ColorPicker, DeviceSelect, SettingsSelect }
})
export default class ToggleForm extends Mixins(Validator) {
  @Prop({ default: null })
  private readonly sensorType: WidgetSensorType

  public formData: WidgetFormData = {
    color: '#f44336',
    deviceId: null,
    settingsName: '',
    param: '',
    trueValue: 'true',
    trueColor: '#F44336',
    trueText: '',
    trueActionPayload: '',
    falseValue: 'false',
    falseColor: '#F44336',
    falseText: '',
    falseActionPayload: ''
  }

  public fillForm(formData: WidgetFormData): void {
    this.formData = formData
  }

  @Watch('formData', { deep: true })
  private widgetFormChanged(): void {
    this.$emit('formChanged', this.formData)
  }
}

interface WidgetFormData {
  color: string
  deviceId: number | null
  settingsName: string
  param: string
  trueValue: string
  trueColor: string
  trueText: string
  trueActionPayload: string
  falseValue: string
  falseColor: string
  falseText: string
  falseActionPayload: string
}
