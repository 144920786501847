import { VuetifySelect } from '@/interfaces/vuetify'
import i18n from '@/plugins/i18n'

export const TIME_ITEMS: VuetifySelect[] = [
  { value: 600, text: i18n.tc('widgetsPage.chartDialog.settingsForm.constants.10MinText') },
  { value: 1200, text: i18n.tc('widgetsPage.chartDialog.settingsForm.constants.20MinText') },
  { value: 1800, text: i18n.tc('widgetsPage.chartDialog.settingsForm.constants.30MinText') },
  { value: 3600, text: i18n.tc('widgetsPage.chartDialog.settingsForm.constants.1HourText') },
  { value: 'custom', text: i18n.tc('widgetsPage.chartDialog.settingsForm.constants.customPeriodText') }
]
