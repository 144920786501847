import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import ColorPicker from '@/App/Components/ColorPicker/Color-Picker.vue'
import DeviceSelect from '@/App/Components/DeviceSelect/Device-Select.vue'
import SensorSelect from '@/App/Components/SensorSelect/Sensor-Select.vue'
import { WidgetSensorType } from '@/types'
import { Validator } from '@/App/Mixins'

@Component({
  components: { ColorPicker, DeviceSelect, SensorSelect }
})
export default class CustomDigitalForm extends Mixins(Validator) {
  @Prop({ default: null })
  private readonly sensorType: WidgetSensorType

  public formData: WidgetFormData = {
    color: '#f44336',
    deviceId: null,
    sensorName: '',
    trueValue: 'true',
    trueColor: '#F44336',
    trueText: '',
    falseValue: 'false',
    falseColor: '#F44336',
    falseText: '',
    prefix: '',
    postfix: ''
  }

  public fillForm(formData: WidgetFormData): void {
    this.formData = {
      ...formData,
      deviceId: formData.deviceId ? +formData.deviceId : null
    }
  }

  @Watch('formData', { deep: true })
  private widgetFormChanged(): void {
    this.$emit('formChanged', this.formData)
  }
}

interface WidgetFormData {
  color: string
  deviceId: number | null
  sensorName: string
  trueValue: string
  trueColor: string
  trueText: string
  falseValue: string
  falseColor: string
  falseText: string
  prefix: string
  postfix: string
}
