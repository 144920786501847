import { Component, Vue, Prop } from 'vue-property-decorator'
import Menu from './Menu/Menu.vue'
import WidgetDialog from '../WidgetDialog/Widget-Dialog.vue'
import IFrameForm from '../Forms/IFrame/IFrame-Form.vue'
import MapForm from '../Forms/Map/Map-Form.vue'
import ToggleForm from '../Forms/Toggle/Toggle-Form.vue'
import TilesForm from '../Forms/Tiles/Tiles-Form.vue'
import SimpleRadialGaugeFrom from '../Forms/SimpleRadialGauge/Simple-Radial-Gauge-Form.vue'
import RadialGaugeForm from '../Forms/RadialGauge/Radial-Gauge-Form.vue'
import LinearGaugeForm from '../Forms/LinearGauge/Linear-Gauge-Form.vue'
import { Position } from '@/App/Pages/Widgets/Forms/Map/MapForm'
import { Widget } from '@/store/interfaces/widget.interface'
import { SimpleRadialGauge } from '@/App/Pages/Widgets/Forms/SimpleRadialGauge/SimpleRadialGaugeForm'
import { GaugeOptions, getToggleFormData, getWidgetColor } from '@/App/Pages/Widgets/Utils'
import { ToggleFormData } from '@/App/Pages/Widgets/Forms/Toggle/ToggleForm'
import { WidgetSensorType } from '@/types'
import { WidgetPositionValue } from '@/App/Services/interfaces/widget.interface'
import { Tiles } from '@/App/Pages/Widgets/Forms/Tiles/TilesForm'


@Component({
  components: { WidgetDialog, Menu, IFrameForm, MapForm, ToggleForm, TilesForm, SimpleRadialGaugeFrom, RadialGaugeForm, LinearGaugeForm }
})
export default class WidgetCard extends Vue {
  @Prop({ required: true })
  public readonly widget: Widget

  @Prop({ required: true })
  public readonly isDashboardShared: boolean

  @Prop({ default: true })
  public readonly isMenuShow: boolean

  @Prop({ default: false })
  public readonly usePreviewModeForControlWidgets: boolean

  private commonFormNames = ['Custom', 'Temperature', 'Voltage', 'Humidity', 'Light (lux)']
  public isTopMenuHovered = false
  public isMapFormDataInvalid = false

  public toggleMenuVisibility() {
    this.isTopMenuHovered = !this.isTopMenuHovered
  }

  get isTilesFormShow(): boolean {
    return (this.commonFormNames.includes(this.widget.sensorType) || this.widget.sensorType === 'Custom digital') &&
      this.widget.visualType === 'tiles'
  }

  get tilesFormData(): Tiles {
    return {
      postfix: this.widget.postfix!,
      unit: this.widget.unit!,
      prefix: this.widget.prefix!
    }
  }

  get tilesFormValue(): string {
    if (this.widget.sensorType === 'Custom digital') {
      return this.widget.trueValue === this.widget.lastValue ? this.widget.trueText! : this.widget.falseText!
    } else {
      return this.widget.lastValue
    }
  }

  get cardBackgroundColor(): string {
    if ((this.isMapFormDataInvalid && this.widget.sensorType === 'Map') || this.widget.sensorType === 'iFrame') {
      return '#ff5252'
    }

    const visualType = ['simpleRadialGauge', 'linearGauge', 'radialGauge']
    if (this.commonFormNames.includes(this.widget.sensorType) && visualType.includes(this.widget.visualType)) {
      return this.widget.color
    }

    return getWidgetColor(this.widget)
  }

  get isMapFormShow(): boolean {
    return this.widget.sensorType === 'Map' && !this.isMapFormDataInvalid
  }

  get isCalibrationTableShow(): boolean {
    return this.commonFormNames.includes(this.widget.sensorType)
  }

  get mapFormData(): Position {
    const { latitude, longitude } = JSON.parse(this.widget.lastValue) as WidgetPositionValue

    return { latitude, longitude }
  }

  get isIFrameFormShow(): boolean {
    return this.widget.sensorType === 'iFrame'
  }

  get isToggleFormShow(): boolean {
    return this.widget.sensorType === 'Toggle'
  }

  get toggleFormData(): ToggleFormData {
    return getToggleFormData(this.widget)
  }

  get isSimpleRadialGaugeShow(): boolean {
    return this.commonFormNames.includes(this.widget.sensorType) && this.widget.visualType === 'simpleRadialGauge'
  }

  get simpleRadialGaugeData(): SimpleRadialGauge {
    return {
      color: getWidgetColor(this.widget),
      max: this.widget.visualTypeMaxValue || 0,
      postfix: this.widget.postfix || '',
      unit: this.widget.unit || '',
      prefix: this.widget.prefix || ''
    }
  }

  get isRadialGaugeFormShow(): boolean {
    return this.commonFormNames.includes(this.widget.sensorType) && this.widget.visualType === 'radialGauge'
  }

  get isLinearGaugeFormShow(): boolean {
    return this.commonFormNames.includes(this.widget.sensorType) && this.widget.visualType === 'linearGauge'
  }

  get gaugeData(): GaugeOptions {
    return {
      colorPlate: this.widget.color,
      highLevelColor: this.widget.highLevelColor,
      highLevelValue: this.widget.highLevelValue,
      lowLevelColor: this.widget.lowLevelColor,
      lowLevelValue: this.widget.lowLevelValue,
      midLevelColor: this.widget.midLevelColor,
      midLevelValue: this.widget.midLevelValue,
      prefix: this.widget.prefix || '',
      unit: this.widget.unit || '',
      postfix: this.widget.postfix || '',
      visualTypeMaxValue: this.widget.visualTypeMaxValue!,
      visualTypeMinValue: this.widget.visualTypeMinValue!,
      visualTypeTickInterval: this.widget.visualTypeTickInterval!
    }
  }

  get mapFormStyleClass(): string | null {
    return this.widget.sensorType === 'Map' ? 'widget-card-footer' : null
  }

  get isMapPositionDataInvalid(): boolean {
    return this.widget.sensorType === 'Map' && this.isMapFormDataInvalid
  }

  get menuStyleClass(): string | null {
    return this.widget.sensorType === 'Map' || this.widget.sensorType === 'iFrame' &&
      this.isTopMenuHovered ? 'widget-card-header-hovered' : null
  }

  get menuTitleStyleClass(): string | null {
    return (this.widget.sensorType === 'Map' || this.widget.sensorType === 'iFrame') &&
      this.isTopMenuHovered ? 'widget-card-header-title-hovered' : null
  }

  get isMenuChartShow(): boolean {
    const executedTypes: WidgetSensorType[] = ['iFrame', 'Map', 'Custom digital', 'Toggle']

    return !executedTypes.includes(this.widget.sensorType)
  }

  get isMenuShortcutShow(): boolean {
    return this.widget.sensorType !== 'iFrame' && this.widget.sensorType !== 'Map'
  }

  get isShowLastUpdateTime(): boolean {
    return this.widget.sensorType !== 'iFrame' && this.widget.showLastUpdateTime
  }

  get isValueShow(): boolean {
    if (this.widget.isMonitoring) {
      return this.widget.isValueShow
    }

    return true
  }
}
