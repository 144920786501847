import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class TilesForm extends Vue {
  @Prop({ required: true })
  public readonly value: string

  @Prop({ required: true })
  public readonly options: Tiles
}

export interface Tiles {
  prefix: string
  unit: string
  postfix: string
}
