import { Component, Mixins, Watch } from 'vue-property-decorator'
import { WidgetSensorType, WidgetType } from '@/types'
import { Validator } from '@/App/Mixins'

@Component
export default class CommonForm extends Mixins(Validator) {
  public formData: WidgetFormData = {
    widgetType: 'visualization',
    sensorType: 'Custom',
    name: ''
  }
  public widgetTypeItems: WidgetType[] = ['visualization', 'control']
  public visualizationTypes: WidgetSensorType[] = [
    'Custom',
    'Temperature',
    'Voltage',
    'Humidity',
    'Light (lux)',
    'Custom digital',
    'Map',
    'iFrame'
  ]
  public controlTypes: WidgetSensorType[] = ['Toggle']

  public widgetTypeChanged(): void {
    this.formData.widgetType === 'visualization' ?
      this.formData.sensorType = 'Custom' :
      this.formData.sensorType = 'Toggle'
  }

  public reset(): void {
  this.formData = {
      widgetType: 'visualization',
      sensorType: 'Custom',
      name: ''
    }
  }

  public fillForm(formData: WidgetFormData): void {
    this.formData = formData
  }

  @Watch('formData', { deep: true })
  private widgetFormChanged(): void {
    this.$emit('formChanged', this.formData)
  }

  get sensorTypeItems(): WidgetSensorType[] {
    return this.formData.widgetType === 'visualization' ? this.visualizationTypes : this.controlTypes
  }
}

interface WidgetFormData {
  widgetType: WidgetType
  sensorType: WidgetSensorType
  name: string
}
