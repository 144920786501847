import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { namespace } from 'vuex-class'


const sharedStore = namespace('Shared')

@Component
export default class CalibrationTable extends Vue {
    @Prop()
    private readonly value: CalibrationsTable

    @sharedStore.Mutation
    private setNotification: (notification: Notification) => void

    @sharedStore.Action
    private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

    @Watch('value', { immediate: true })
    public valueChanged(val: CalibrationsTable) {
        this.calibrationTable = val
    }

    public close(): void {
        this.isOpen = false
        this.calibrationTableId = null
    }

    public isOpen = false
    public file = ''
    public parsed = false
    public isLoading = false
    public calibrationTableId: string | null = null

    public calibrationTable: CalibrationsTable = {
        max: 0,
        min: 0,
        values: []
    }


    handleFileUpload( event: any ) {
        this.file = event.target.files[0]
    }


    public parseFile(): void {
        //@ts-ignore
       this.$papa.parse(this.file, {
           download: true,
           header: true,
           skipEmptyLines: true,
           complete: ( results: any ) => {
               console.log(results)
               this.parsed = true
               this.calibrationTable.values = results.data.map( ( r : any ) => ({
                   id: (Math.random() * (1000 - 1) + 1),
                   a: r.a,
                   b: r.b
               }))
           }
       })
    }


    public onCalibrationsAdd(): void {
        const id = (Math.random() * (1000 - 1) + 1).toString()
        this.calibrationTable.values.push({
            id,
            a: 0,
            b: 0
        })
    }

    public async open(): Promise<void> {
        this.$emit('onCalibrationOpen', this.calibrationTable)
        this.isOpen = true
    }


    public async onSubmit(): Promise<void> {
        this.$emit('input', this.calibrationTable)
        this.isOpen = false
    }


    public onCalibrationRemove(id: string): void {
        this.calibrationTable.values = this.calibrationTable.values.filter(value => value.id !== id)
    }


}
interface CalibrationsTable {
    max: number
    min: number
    values: {
        id: string
        a: number
        b: number
    }[]
}