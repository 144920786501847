import { Component, Watch } from 'vue-property-decorator'
import { LinearGauge, LinearGaugeOptions, RenderTarget } from 'canvas-gauges'
import { VisualizationCore } from '@/App/Pages/Widgets/Utils'
import { ResizeEvent } from '@/plugins/vue-resize-observer'

@Component
export default class LinearGaugeForm extends VisualizationCore {
  private chart: LinearGauge
  private gaugeOptions: LinearGaugeOptions = {
    ...this.commonOptions,
    valueBox: false,
    colorBarProgress: '#327AC0'
  }
  public valueTextClass: 'value-position-horizontal' | 'value-position-vertical' = 'value-position-horizontal'

  public mounted(): void {
    this.gaugeOptions.renderTo = this.$refs.el as RenderTarget
    setTimeout(() => {
      this.gaugeOptions = this.fillOptions({
        ...this.gaugeOptions,
        width: this.$el.clientWidth,
        height: this.$el.clientHeight - 50
      }, this.options)
      this.chart = new LinearGauge(this.gaugeOptions).draw()

      this.valueTextClass = this.$el.clientHeight < 250 ? 'value-position-horizontal' : 'value-position-vertical'

      this.chart.value = this.value
    }, 50)
  }

  public cardResized({ height, width }: ResizeEvent): void {
    this.gaugeOptions = {
      ...this.gaugeOptions,
      height: height - 50,
      width
    }

    this.valueTextClass = height < 250 ? 'value-position-horizontal' : 'value-position-vertical'

    if (this.chart) {
      this.chart.update(this.gaugeOptions)
    }
  }

  @Watch('value')
  private valueChanged(value: number): void {
    this.chart.value = value
  }

  @Watch('options')
  private widgetOptionsChanged(): void {
    this.gaugeOptions = this.fillOptions(this.gaugeOptions, this.options)

    this.chart.update(this.gaugeOptions)
  }

  public beforeDestroy(): void {
    this.chart.destroy()
  }
}
