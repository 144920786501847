import { Component, Vue, Prop } from 'vue-property-decorator'
import { ResizeEvent } from '@/plugins/vue-resize-observer'
import CircularLoader from '@/App/Components/CircularLoader/Circular-Loader.vue'

@Component({
  components: { CircularLoader }
})
export default class SimpleRadialGaugeForm extends Vue {
  @Prop({ required: true })
  public value: number

  @Prop({ required: true })
  public options: SimpleRadialGauge

  public height = 0

  public cardResized({ height }: ResizeEvent): void {
    this.height = height - 50
  }

  public mounted(): void {
    setTimeout(() => this.height = this.$el.clientHeight - 50, 1000)
  }

  get percent(): number {
    return this.value / this.options.max * 100
  }
}

export interface SimpleRadialGauge {
  max: number
  color: string
  prefix: string
  postfix: string
  unit: string
}
