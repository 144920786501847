import { Component, Vue } from 'vue-property-decorator'
import WidgetCard from '@/App/Pages/Widgets/WidgetCard/Widget-Card.vue'
import WidgetDialog from '@/App/Pages/Widgets/WidgetDialog/Widget-Dialog.vue'
import { GridLayout, GridItem, GridItemData } from 'vue-grid-layout'
import { WidgetService } from '@/App/Services/Widget.service'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { namespace } from 'vuex-class'
import { UpdateWidget, Widget } from '@/store/interfaces/widget.interface'
import { Dashboard } from '@/App/Services/interfaces/dashboard.interface'
import { DashboardService } from '@/App/Services/Dashboard.service'
import { UserInfo } from '@/App/Services/interfaces/user.interface'
import ChartDialog from '@/App/Pages/Widgets/ChartDialog/Chart-Dialog.vue'

const sharedStore = namespace('Shared')
const widgetStore = namespace('Widget')
const dashboardStore = namespace('Dashboard')

@Component({
    components: { GridLayout, GridItem, WidgetCard, ChartDialog, WidgetDialog }
})
export default class VisualizationView extends Vue {
    @sharedStore.State
    private readonly userInfo: UserInfo

    @sharedStore.Mutation
    private readonly setNotification: (notification: Notification) => void
    @dashboardStore.Getter findDashboardById: (id: string) => Dashboard
    @widgetStore.Mutation setWidgets: (widgets: Widget[]) => void
    @widgetStore.Mutation deleteWidget: (id: string) => void
    @widgetStore.Mutation updateWidget: (widget: UpdateWidget) => void
    @widgetStore.Mutation addWidget: (widget: Widget) => void
    @widgetStore.State public widgets: Widget[]

    @sharedStore.Action
    private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

    public onSave(widget: Widget): void {
        this.addWidget({ ...widget, dashboardId: this.$route.params.id })
        this.layout.push({
            x: widget.layout.x,
            y: widget.layout.y,
            w: widget.layout.w,
            h: widget.layout.h,
            i: widget._id
        })
    }

    public onUpdate(widget: Widget): void {
        this.updateWidget(widget)
    }

    public isLoading = false
    public layout: GridItemData[] = []
    public dashboard: Dashboard = {
        _id: '',
        name: ''
    }

    public async onWidgetResized(id: string, h: number, w: number) {
        try {
            await WidgetService.updateLayout(this.$route.params.id, id, { w, h })
        } catch {}
    }

    public async onWidgetMoved(id: string, x: number, y: number) {
        try {
            await WidgetService.updateLayout(this.$route.params.id, id, { x, y })
        } catch {}
    }

    public async onDelete(id: string, layoutIndex: number): Promise<void> {
        try {
            const title = this.$t('widgetsPage.deleteWidgetConfirmTitle').toString()
            const text = `
        ${this.$t('widgetsPage.deleteWidgetConformText1')}<br/>
        ${this.$t('widgetsPage.deleteWidgetConfirmText2')}<br/>
        ${this.$t('widgetsPage.deleteWidgetConfirmText3')}
    `

            if (await this.confirm({ title, text })) {
                await WidgetService.destroy(this.$route.params.id, id)

                this.deleteWidget(id)
                this.layout.splice(layoutIndex, 1)

                this.setNotification({ text: this.$t('widgetsPage.widgetDeletedNotificationText').toString() })
            }
        } catch {}
    }

    public async onCopy(id: string): Promise<void> {
        try {
            const title = this.$t('widgetsPage.copyWidgetConfirmTitle').toString()
            const text = `
        ${this.$t('widgetsPage.copyWidgetConfirmText1')}<br/>
        ${this.$t('widgetsPage.copyWidgetConfirmText2')}
    `

            if (await this.confirm({ title, text, agreeBtnColor: 'success', disagreeBtnColor: 'error' })) {
                const widget = await WidgetService.copy(this.$route.params.id, id)

                this.onSave(widget)

                this.setNotification({ text: this.$t('widgetsPage.widgetCopedNotificationText').toString() })
                // Open widget edit window after widget was created, if user want to edit new coped widget
                // @ts-ignore
                this.$refs.dialog.open(this.widgets.find(w => w._id === widget._id))
            }
        } catch {}
    }

    public async onToggleShortcut(id: string, isShortcut: boolean) {
        try {
            await WidgetService.update(this.$route.params.id, id, { isShortcut })
            this.updateWidget({ _id: id, isShortcut })

            const text = isShortcut
                ? this.$t('widgetsPage.widgetAddedShortcutText') .toString()
                : this.$t('widgetsPage.widgetRemovedShortcutText').toString()
            this.setNotification({ text })
        } catch {}
    }

    private async mounted() {
        try {
            this.isLoading = true

            const dashboard = this.findDashboardById(this.$route.params.id)
            if (dashboard) {
                this.dashboard = dashboard
            } else {
                this.dashboard = await DashboardService.getById(this.$route.params.id)
            }

            let widgets = await WidgetService.fetch(this.$route.params.id)
            widgets = widgets.map(w => ({ ...w, dashboardId: this.$route.params.id }))
            this.setWidgets(widgets)

            // @ts-ignore layout must be as not required field in future maybe split this into separate interfaces
            this.widgets.forEach(({ _id, layout: { x, y, w, h } }) =>
                this.layout.push({ x, y, w, h, i: _id }))
        } catch {} finally {
            this.isLoading = false
        }
    }

    get isWidgetDialogRender(): boolean {
        return this.userInfo.role !== 'operator'
    }
}
