import { Component, Mixins, Watch } from 'vue-property-decorator'
import ColorPicker from '@/App/Components/ColorPicker/Color-Picker.vue'
import DeviceSelect from '@/App/Components/DeviceSelect/Device-Select.vue'
import { Validator } from '@/App/Mixins'

// TODO add check is position sensor exist on current device
@Component({
  components: { ColorPicker, DeviceSelect }
})
export default class MapForm extends Mixins(Validator) {
  public deviceId: null | number = null

  public fillForm(deviceId: number): void {
    this.deviceId = +deviceId
  }

  @Watch('deviceId' )
  private widgetFormChanged(deviceId: number): void {
    this.$emit('formChanged', {
      deviceId,
      color: '#f44336',
      sensorName: 'position'
    })
  }
}
