import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import ColorPicker from '@/App/Components/ColorPicker/Color-Picker.vue'
import CalibrationTable from '@/App/Components/CalibrationTable/Calibration-Table.vue'
import DeviceSelect from '@/App/Components/DeviceSelect/Device-Select.vue'
import SensorSelect from '@/App/Components/SensorSelect/Sensor-Select.vue'
import { Units, WidgetSensorType, WidgetVisualType } from '@/types'
import { Validator } from '@/App/Mixins'
import { VuetifySelect } from '@/interfaces/vuetify'

const FORM_DATA: WidgetFormData = {
  color: '#f44336',
  deviceId: null,
  sensorName: '',
  lowLevelValue: 0,
  lowLevelColor: '#f44336',
  midLevelValue: 0,
  midLevelColor: '#f44336',
  highLevelValue: 0,
  highLevelColor: '#f44336',
  mathExpression: '',
  calibrationTable: {
    max : 0,
    min: 0,
    values: []
  },
  rounding: null,
  prefix: '',
  postfix: '',
  unit: null,
  visualType: 'tiles',
  visualTypeMaxValue: 220,
  visualTypeMinValue: 0,
  visualTypeTickInterval: 20
}

@Component({
  components: { ColorPicker, DeviceSelect, SensorSelect, CalibrationTable }
})
export default class CustomForm extends Mixins(Validator) {
  $refs: {
    calibrationTable: HTMLFormElement
  }

  @Prop({ default: null })
  private readonly sensorType: WidgetSensorType

  public unitItems: Units[] = [ 'Kmph', 'Mph', 'Liters', 'Gallon', 'Kms', 'Miles' ]
  public visualTypeItems: VuetifySelect[] = [
    { text: this.$t('widgetsPage.widgetDialog.forms.custom.tilesText').toString(), value: 'tiles' },
    { text: this.$t('widgetsPage.widgetDialog.forms.custom.linearGaugeText').toString(), value: 'linearGauge' },
    { text: this.$t('widgetsPage.widgetDialog.forms.custom.radialGauge').toString(), value: 'radialGauge' },
    { text: this.$t('widgetsPage.widgetDialog.forms.custom.simpleRadialGauge').toString(), value: 'simpleRadialGauge' }
  ]
  public formData: WidgetFormData = { ...FORM_DATA }

  public reset(): void {
    this.formData = { ...FORM_DATA }
  }


  public fillForm(formData: WidgetFormData): void {
    this.formData = formData
  }

  @Watch('formData', { deep: true })
  private widgetFormChanged(): void {
    this.$emit('formChanged', this.formData)
  }

  @Watch('sensorType')
  private sensorTypeChanged(): void {
    if (this.sensorType === 'Custom') {
      this.unitItems = [ 'Kmph', 'Mph', 'Liters', 'Gallon', 'Kms', 'Miles' ]
    }

    if (this.sensorType === 'Temperature') {
      this.unitItems = [ '°C', '°F' ]
    }
  }

  get isMeasurementUnitsShow(): boolean {
    return this.sensorType === 'Custom' || this.sensorType === 'Temperature'
  }

  get isWidgetVisualTypeSettingsShow(): boolean {
    return this.formData.visualType !== 'tiles'
  }

  get colSize(): number {
    return this.sensorType === 'Custom' || this.sensorType === 'Temperature' ? 4 : 6
  }

}

interface WidgetFormData {
  color: string
  deviceId: number | null
  sensorName: string
  lowLevelValue: number
  lowLevelColor: string
  midLevelValue: number
  midLevelColor: string
  highLevelValue: number
  highLevelColor: string
  mathExpression: string
  calibrationTable: {
  max: number
  min: number
  values: {
    id: string
    a: number
    b: number
  }[]
  }
  rounding: number | null
  prefix: string
  postfix: string
  unit: Units | null
  visualType: WidgetVisualType
  visualTypeMaxValue: number
  visualTypeMinValue: number
  visualTypeTickInterval: number
}
