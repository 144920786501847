import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Validator } from '@/App/Mixins'
import { DashboardTemplatesService } from '@/App/Services/DashboardTemplatesService'
import { VuetifySelect } from '@/interfaces/vuetify'



@Component
export default class DashboardTemplateSelect extends Mixins(Validator) {

    @Prop({ default: '' })
    public readonly value: string

    @Prop()
    public readonly required!: boolean

    public isLoading = false
    public dashboardTemplateId = ''
    public dashboardsTemplateItems: VuetifySelect[] = []

    public onDashboardTemplateSelect(): void {
        this.$emit('input', this.dashboardTemplateId)
    }

    @Watch('value')
    private valueChanged(val: string ): void {
        this.dashboardTemplateId = val
    }

    public async mounted(): Promise<void> {
        try {
            this.isLoading = true

            const dashboardsTemplates = await DashboardTemplatesService.fetch()

            this.dashboardsTemplateItems = dashboardsTemplates.map(item => ({
                value: item._id,
                text: item.name
            }))
        } catch {} finally {
            this.isLoading = false
        }
    }
}