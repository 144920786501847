import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import WidgetCard from '../../WidgetCard/Widget-Card.vue'
import { Widget } from '@/store/interfaces/widget.interface'

@Component({
  components: { WidgetCard }
})
export default class PreviewDialog extends Vue {
  @Prop({ required: true })
  private readonly widget: Widget

  @Prop({ default: false })
  private readonly isDisabled: boolean

  public isOpen = false
  public localWidgetCopy: Widget = { ...this.widget }
  public interval: number | undefined

  @Watch('isOpen')
  private dialogStateChanged(): void {
    if (this.isOpen) {
      this.setLocalWidgetValue()

      this.interval = setInterval(() => this.setLocalWidgetValue(), 3000)
    } else {
      clearInterval(this.interval)
    }
  }

  private setLocalWidgetValue(): void {
    if (this.widget.sensorType === 'Toggle' || this.widget.sensorType === 'Custom digital') {
      this.localWidgetCopy = {
        ...this.widget,
        lastUpdateTime: +(Date.now() / 1000).toFixed(0),
        lastValue: this.localWidgetCopy.lastValue === this.widget.trueValue ?
          this.widget.falseValue! :
          this.widget.trueValue!
      }
    }

    if (['Custom', 'Temperature', 'Voltage', 'Humidity', 'Light (lux)'].includes(this.widget.sensorType)) {
      const val = Math.random() * (this.widget.highLevelValue! - this.widget.lowLevelValue!) + this.widget.lowLevelValue!

      this.localWidgetCopy = {
        ...this.widget,
        lastUpdateTime: +(Date.now() / 1000).toFixed(0),
        lastValue: parseFloat(val.toString()).toFixed(2)
      }
    }

    if (this.widget.sensorType === 'Map') {
      this.localWidgetCopy = {
        ...this.widget,
        lastUpdateTime: +(Date.now() / 1000).toFixed(0),
        lastValue: '{"latitude":7.003167,"longitude":79.949182}'
      }
    }

    if (this.widget.sensorType === 'iFrame') {
      this.localWidgetCopy = { ...this.widget }
    }
  }

  get isPreviewLarge(): boolean {
    return this.widget.sensorType === 'iFrame' || this.widget.sensorType === 'Map'
  }

  get dialogMaxWidth(): number {
    return this.isPreviewLarge ? 450 : 300
  }

  get dialogContentCass(): 'dialog-content-wrapper' | 'dialog-content-wrapper-large' {
    return this.isPreviewLarge ? 'dialog-content-wrapper-large' : 'dialog-content-wrapper'
  }
}
