import { Component, Mixins, Emit } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import CommonForm from './Forms/Common/Common-Form.vue'
import IFrameForm from './Forms/IFrame/IFrame-Form.vue'
import CustomForm from './Forms/Custom/Custom-Form.vue'
import CustomDigitalForm from './Forms/CustomDigital/Custom-Digital-Form.vue'
import ToggleForm from './Forms/Toggle/Toggle-Form.vue'
import MapForm from './Forms/Map/Map-Form.vue'
import PreviewDialog from './PreviewDialog/Preview-Dialog.vue'
import { WidgetService } from '@/App/Services/Widget.service'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { Widget, WidgetForm, WidgetFormEdit } from '@/store/interfaces/widget.interface'
import { VuetifySelect } from '@/interfaces/vuetify'

const sharedStore = namespace('Shared')

@Component({
  components: { CommonForm, IFrameForm, CustomForm, MapForm, CustomDigitalForm, ToggleForm, PreviewDialog }
})
export default class WidgetDialog extends Mixins(Validator) {
  $refs!: {
    form: HTMLFormElement,
    commonForm: HTMLFormElement,
    customForm: HTMLFormElement,
    customDigitalForm: HTMLFormElement,
    iFrameForm: HTMLFormElement,
    mapForm: HTMLFormElement,
    toggleForm: HTMLFormElement
  }

  @sharedStore.Mutation
  private setNotification: (notification: Notification) => void


  public isOpen = false
  public isLoading = false
  public form = false

  private customFormNames: string[] = ['Custom', 'Temperature', 'Voltage', 'Humidity', 'Light (lux)']
  public widgetId: string | null = null
  public durationItems: VuetifySelect[] = [
    { value: 300, text: '5 min' },
    { value: 900, text: '15 min' },
    { value: 1800, text: '30 min' },
    { value: 3600, text: '60 min' }
  ]
  public widget = {
    widgetType: 'visualization',
    sensorType: 'Custom',
    isValueShow: true
  } as WidgetForm

  public async open(widgetData?: WidgetFormEdit): Promise<void> {
    if (widgetData) {
      const { _id, ...data } = widgetData

      this.widgetId = _id
      this.widget = data

      this.$refs.commonForm.fillForm({
        widgetType: widgetData.widgetType,
        sensorType: widgetData.sensorType,
        name: widgetData.name
      })
      // wait till $refs will available after v-if status of form will change
      await new Promise(r => setTimeout(r, 50))

      if (widgetData.sensorType === 'Map') {
        this.$refs.mapForm.fillForm(widgetData.deviceId)
      }

      if (widgetData.sensorType === 'iFrame') {
        this.$refs.iFrameForm.fillForm(widgetData.iFrameLink)
      }

      if (this.customFormNames.includes(widgetData.sensorType)) {
        this.$refs.customForm.fillForm({
          color: widgetData.color,
          deviceId: widgetData.deviceId,
          sensorName: widgetData.sensorName,
          lowLevelValue: widgetData.lowLevelValue,
          lowLevelColor: widgetData.lowLevelColor,
          midLevelValue: widgetData.midLevelValue,
          midLevelColor: widgetData.midLevelColor,
          highLevelValue: widgetData.highLevelValue,
          highLevelColor: widgetData.highLevelColor,
          mathExpression: widgetData.mathExpression,
          calibrationTable: {
            ...widgetData.calibrationTable,
            values: widgetData.calibrationTable?.values.map(({ _id, a, b }) => ({ id: _id, a, b }))
          },
          rounding: widgetData.rounding,
          prefix: widgetData.prefix,
          postfix: widgetData.postfix,
          unit: widgetData.unit,
          visualType: widgetData.visualType,
          visualTypeMaxValue: widgetData.visualTypeMaxValue,
          visualTypeMinValue: widgetData.visualTypeMinValue,
          visualTypeTickInterval: widgetData.visualTypeTickInterval
        })
      }

      if (widgetData.sensorType === 'Custom digital') {
        this.$refs.customDigitalForm.fillForm({
          color: widgetData.color,
          deviceId: widgetData.deviceId,
          sensorName: widgetData.sensorName,
          trueValue: widgetData.trueValue,
          trueColor: widgetData.trueColor,
          trueText: widgetData.trueText,
          falseValue: widgetData.falseValue,
          falseColor: widgetData.falseColor,
          falseText: widgetData.falseText,
          prefix: widgetData.prefix,
          postfix: widgetData.postfix

        })
      }

      if (widgetData.sensorType === 'Toggle') {
        this.$refs.toggleForm.fillForm({
          color: widgetData.color,
          deviceId: widgetData.deviceId,
          settingsName: widgetData.settingsName,
          param: widgetData.param || '',
          trueValue: widgetData.trueValue,
          trueColor: widgetData.trueColor,
          trueText: widgetData.trueText,
          trueActionPayload: widgetData.trueActionPayload,
          falseValue: widgetData.falseValue,
          falseColor: widgetData.falseColor,
          falseText: widgetData.falseText,
          falseActionPayload: widgetData.falseActionPayload
        })
      }
    }

    this.isOpen = true
  }

  public close(): void {
    this.isOpen = false

    this.widget = {
      widgetType: 'visualization',
      sensorType: 'Custom'
    } as WidgetForm
    this.widgetId = null

    this.$refs.form.resetValidation()
    this.$refs.commonForm.reset()
    this.$refs.customForm?.reset()
  }

  public submit(): void {
    this.widgetId ? this.update() : this.save()
  }

  public async save(): Promise<void> {
    try {
      this.isLoading = true

      const formData = { ...this.widget }
      if (!this.widget.calibrationTable?.values.length || !this.widget.calibrationTable.max || !this.widget.calibrationTable.min) {
        delete this.widget.calibrationTable
      }

      this.widgetStored(await WidgetService.store(this.$route.params.id, formData))

      this.setNotification({ text: this.$t('widgetsPage.widgetDialog.widgetCreatedNotificationText').toString() })

      this.close()
    } catch {} finally {
      this.isLoading = false
    }
  }

  public async update(): Promise<void> {
    try {
      if (!this.widgetId) {
        return
      }

      this.isLoading = true

      const formData = { ...this.widget }

      if (!this.widget.calibrationTable?.values ||
        this.widget.calibrationTable.values?.length ||
        !this.widget.calibrationTable.max ||
        !this.widget.calibrationTable.min) {
        delete formData.calibrationTable
      }

      const widget = await WidgetService.update(this.$route.params.id, this.widgetId, formData)


      this.setNotification({ text: this.$t('widgetsPage.widgetDialog.widgetUpdatedNotificationText').toString() })

      this.onWidgetUpdated(widget)

      this.close()
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  }

  @Emit('onWidgetStored')
  private widgetStored(widget: Widget): Widget {
    return widget
  }

  @Emit('onWidgetUpdated')
  onWidgetUpdated(widget: Widget): Widget {
    return widget
  }

  public formChanged(formData: Partial<WidgetForm>): void {
    if (
      (formData.widgetType && formData.widgetType !== this.widget.widgetType) ||
      (formData.sensorType && formData.sensorType !== this.widget.sensorType)
    ) {
      this.widget = {} as WidgetForm
    }

    this.widget = { ...this.widget, ...formData }
  }

  get dialogTitle(): string {
    return this.widgetId
      ? this.$t('widgetsPage.widgetDialog.editWidgetText').toString()
      : this.$t('widgetsPage.widgetDialog.creatingNewWidgetText').toString()
  }

  get submitBtnText(): string {
    return this.widgetId
      ? this.$t('widgetsPage.widgetDialog.updateText').toString()
      : this.$t('widgetsPage.widgetDialog.saveText').toString()
  }

  get isCustomFormShow(): boolean {
    return this.customFormNames.includes(this.widget.sensorType)
  }

  get isIFrameFormShow(): boolean {
    return this.widget.sensorType === 'iFrame'
  }

  get isMapFormShow(): boolean {
    return this.widget.sensorType === 'Map'
  }

  get isCustomDigitalFormShow(): boolean {
    return this.widget.sensorType === 'Custom digital'
  }

  get isToggleFormShow(): boolean {
    return this.widget.sensorType === 'Toggle'
  }

  get isLastUpdateTimeSwitcherShow(): boolean {
    return this.widget.sensorType !== 'iFrame'
  }

  get isSubmitFormDisabled(): boolean {
    return !this.form || this.isLoading
  }
}
