import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class ColorPicker extends Vue {
  @Prop({ default: '' })
  private readonly value: string

  public isOpen = false
  public color = '#f44336'

  public mounted(): void {
    this.color = this.value
  }

  public colorSelected() {
    this.$emit('input', this.color)
    this.isOpen = false
  }

  public close(): void {
    this.color = '#f44336'
  }

  @Watch('value')
  public valueChanged(val: string) {
    this.color = val
  }
}
